module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/src/node_modules/gatsby-remark-code-titles","id":"2f4fcfd7-7d2e-5dcc-a351-1ef3918099a6","name":"gatsby-remark-code-titles","version":"1.1.0","modulePath":"/src/node_modules/gatsby-remark-code-titles/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/src/node_modules/gatsby-remark-plantuml-lite","id":"f0812475-403c-5422-b343-b73dbb091833","name":"gatsby-remark-plantuml-lite","version":"0.1.3","modulePath":"/src/node_modules/gatsby-remark-plantuml-lite/dist/index.js","pluginOptions":{"plugins":[],"imageType":"svg","server":"https://www.plantuml.com/plantuml"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/src","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
